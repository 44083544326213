.SideBar{
    height: 100vh;
    width: 210px;
    min-width: 210px;
    display: flex;
    flex-direction: column;
    background-color: #0d0e38;
}

.Logo{
    height: 40px;
    width: 130px;
    margin: 20px 25px;
    background-image: url('../assets/logo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.SideBar_Con{
    height: 45px;
    display: flex;
    align-items: center;
    margin: 0 10px;
    padding: 0 15px;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 10px;
    color: rgb(174, 174, 174);
    cursor: pointer;
    text-decoration: none;
}

.SideBar_Con_Select{
    background-color: rgba(207, 212, 228, 0.11);
    color: #fff;
}

.SideBar p{
    margin: 0;
    margin-left: 10px;
    font-size: 16px;
}

.Header{
    min-height: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid rgb(207, 217, 228);
}

.Header_Con{
    width: 92.5%;
    display: flex;
    align-items: center;
}

.Header h2{
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}

.Header button{
    height: 35px;
    width: fit-content;
    background-color: #0091da;
    border: none;
    outline: none;
    border-radius: 8px;
    color: #fff;
    margin: 0 20px;
    padding: 0 20px;
    font-size: 15px;
    cursor: pointer;
}

.Header select{
    height: 34px;
    border-radius: 8px;
    /* width: 130px; */
    padding: 0 10px;
    font-size: 15px;
    outline: 1px solid #dddddd;
    border: none;
    border-right: 10px solid #fff;
    transition: 0.2s ease;
    margin-right: 20px;
}

.FlexCon{
    flex: 1;
}