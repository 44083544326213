.Login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(244, 249, 255);
}

.Login_Con {
  height: fit-content;
  width: 370px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 5px #d2d2d2;
  padding-bottom: 20px;
}

.Login h1 {
  margin: 0 20px;
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-top: 20px;
}

.Login h2 {
  margin: 0 20px;
  font-size: 16px;
  font-weight: 500;
  color: #0091da;
  margin-top: 10px;
}

.Login form {
  display: flex;
  flex-direction: column;
}

.Login label {
  margin: 0 20px;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin-top: 20px;
}

.Login input {
  height: 35px;
  border-radius: 8px;
  width: calc(100% - 60px);
  margin: 0 20px;
  margin-top: 8px;
  padding: 0 10px;
  font-size: 15px;
  border: 1px solid #dddddd;
  outline: none;
  transition: 0.2s ease;
}

.Login input:focus {
  border: 1px solid #9ab1c8;
}

.Login button {
  height: 35px;
  border-radius: 8px;
  width: calc(100% - 40px);
  margin: 0 20px;
  margin-top: 20px;
  font-size: 15px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  background-color: #0091da;
}

.Loading {
  display: flex;
  height: calc(100vh - 100px);
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgb(244, 249, 255);
}

.Home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 249, 255);
  overflow: scroll;
}

.Home table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 92.5%;
  margin-left: 3.75%;
  margin-top: 25px;
  border-radius: 8px;
  background-color: #fff;
  outline: 1px solid rgb(207, 217, 228);
  user-select: none;
}

.Home td {
  border-top: 1px solid rgb(207, 217, 228);
  border-left: 1px solid rgb(207, 217, 228);
  text-align: left;
  padding: 12.5px;
}

.Home tr {
  cursor: pointer;
}

.Home th {
  text-align: left;
  padding: 12.5px;
  border-left: 1px solid rgb(207, 217, 228);
}

.Home tr:nth-child(even) {
  background-color: #fbfdff;
}

.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease;
}

.Modal_Show {
  visibility: visible;
  opacity: 1;
}

.Modal_Con {
  height: fit-content;
  width: 450px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  padding-bottom: 15px;
}

.Modal_Close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.Modal h2 {
  margin: 0 15px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.Modal_Head{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Modal form {
  display: flex;
  flex-direction: column;
}

.Modal_Input_Con {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  justify-content: space-between;
  padding: 0 15px;
  max-height: 250px;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 10px;
}

.Modal_Input_Con2 {
  display: grid;
  grid-template-columns: calc(32.5% - 5px) calc(30% - 5px) calc(20% - 5px) calc(15% - 5px);
  justify-content: space-between;
  overflow: auto;
  margin: 0 15px;
  margin-bottom: 15px;
  margin-top: 10px;
  padding-left: 1px;
}

.Modal_Input_Con p{
  margin: 0;
  margin-top: 10px;
}

/* .Modal_Input_Con::-webkit-scrollbar {
  display: none;
} */

.Modal_Input_Con1{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.Modal_Input_Con1 p{
  margin: 0;
  font-size: 15px;
}

.Modal_Input_Con3{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Modal input {
  height: 35px;
  border-radius: 8px;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 15px;
  border: 1px solid #dddddd;
  outline: none;
  transition: 0.2s ease;
}

.Modal textarea{
  height: 45px;
  border-radius: 8px;
  margin-top: 10px;
  padding: 10px;
  font-size: 15px;
  border: 1px solid #dddddd;
  outline: none;
  transition: 0.2s ease;
  resize: vertical;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.Modal select {
  height: 34px;
  border-radius: 8px;
  width: calc(100% - 1px);
  margin-top: 10px;
  padding: 0 10px;
  font-size: 15px;
  outline: 1px solid #dddddd;
  border: none;
  border-right: 10px solid #fff;
  transition: 0.2s ease;
}

.Modal_Head select{
  width: fit-content;
  margin-top: 15px;
  margin-right: 30px;
}

.Modal_Btn_Con {
  display: flex;
  justify-content: right;
  margin: 0 15px;
}

.Modal button {
  height: 35px;
  border-radius: 8px;
  width: 100px;
  font-size: 15px;
  margin-top: 10px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  background-color: #0091da;
  margin-left: 15px;
}

.Modal_Btn_Con select {
  width: fit-content;
}

.Modal_Input_Con2 button{
  width: 100%;
  margin: 0;
  margin-top: 10px;
  background-color: #eaeaea;
  color: #000;
}

.Modal_Filter_Con{
  display: flex;
  flex-wrap: wrap;
  margin: 0 15px;
  margin-top: 5px;
  gap: 0 10px;
}

.Modal_Filter{
  padding: 5px 10px;
  width: fit-content;
  border-radius: 6px;
  color: #fff;
  background-color: #0d0e38;
  margin-top: 10px;
  cursor: pointer;
}

.Answer {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 249, 255);
}

.Answer span {
  height: calc(100vh - 81px);
  overflow: scroll;
}

.Answer_Con {
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 81px); */
  /* overflow: scroll; */
  padding-bottom: 25px;
}

.Answer_Con1 {
  display: flex;
  /* min-height: 400px; */
  /* height: fit-content; */
  flex-direction: column;
  /* width: 92.5%; */
  max-width: calc(100vw - 300px);
  margin-left: 3.75%;
  background-color: #fff;
  margin-top: 25px;
  border-radius: 10px;
  outline: 1px solid rgb(207, 217, 228);
  padding-bottom: 20px;
  overflow: auto;
}

.Answer_Con h2 {
  margin: 0 20px;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 500;
  position: sticky;
  left: 20px;
}

.Answer p {
  margin: 0 20px;
  margin-top: 15px;
  font-size: 16px;
}

.Answer p b {
  color: #0091da;
  font-weight: 500;
}

.Answer table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border-radius: 8px;
  background-color: #fff;
  outline: 1px solid rgb(207, 217, 228);
  user-select: none;
  margin: 0 20px;
  margin-top: 25px;
}

.Answer td {
  border-top: 1px solid rgb(207, 217, 228);
  border-left: 1px solid rgb(207, 217, 228);
  text-align: left;
  padding: 12.5px;
}

.Answer tr {
  cursor: pointer;
}

.Answer th {
  /* min-width: 300px; */
  text-align: left;
  padding: 12.5px;
  font-weight: 500;
  border-left: 1px solid rgb(207, 217, 228);
}

.Answer tr:nth-child(even) {
  background-color: #fbfdff;
}

.Target {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 249, 255);
}

.Target span {
  height: calc(100vh - 101px);
  max-width: calc(100vw - 200px);
  overflow: scroll;
  padding-bottom: 20px;
}

.Target_Con {
  display: flex;
  flex-direction: column;
  width: 92.5%;
  margin-left: 3.75%;
  background-color: #fff;
  margin-top: 25px;
  border-radius: 10px;
  outline: 1px solid rgb(207, 217, 228);
  padding-bottom: 20px;
  overflow: auto;
}

.Target_Con_Head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.Target_Con_Head button {
  height: 35px;
  padding: 0 15px;
  background-color: #0091da;
  border: none;
  outline: none;
  border-radius: 8px;
  color: #fff;
  margin: 0 20px;
  font-size: 15px;
  cursor: pointer;
}

.Target_Con h2 {
  margin: 0 20px;
  font-size: 20px;
  font-weight: 500;
  position: sticky;
  left: 20px;
}

.Target table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border-radius: 8px;
  background-color: #fff;
  outline: 1px solid rgb(207, 217, 228);
  user-select: none;
  margin: 0 20px;
  margin-top: 25px;
}

.Target td {
  border-top: 1px solid rgb(207, 217, 228);
  border-left: 1px solid rgb(207, 217, 228);
  text-align: left;
  padding: 12.5px;
}

.Target tr {
  cursor: pointer;
}

.Target th {
  /* min-width: 300px; */
  text-align: left;
  padding: 12.5px;
  font-weight: 500;
  border-left: 1px solid rgb(207, 217, 228);
}

.Target tr:nth-child(even) {
  background-color: #fbfdff;
}

.Report_Graph_Con{
  display: grid;
  grid-template-columns: calc(33.33% - 10px) calc(33.33% - 10px) calc(33.33% - 10px);
  height: fit-content;
  width: calc(100% - 40px);
  margin-left: 20px;
  justify-content: space-between;
}

.Report_Graph{
  height: fit-content;
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  outline: 1px solid rgb(207, 217, 228);
  padding-bottom: 5px;
}

.Dashboard{
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 249, 255);
}

.Dashboard span{
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 0 3.75%; */
  overflow: scroll;
}

.Dashboard_Grid_Con{
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  justify-content: space-between;
  margin: 0 3.75%;;
}

.Dashboard_Grid_Con1{
  display: grid;
  grid-template-columns: calc(33.33% - 10px) calc(33.33% - 10px) calc(33.33% - 10px);
  justify-content: space-between;
  margin: 0 3.75%;;
  margin-bottom: 20px;
  margin-top: 10px;
}

.Dashboard_Grid_Item1{
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 15px;
  outline: 1px solid rgb(207, 217, 228);
  overflow: hidden;
}

.Dashboard_Grid_Item{
  width: 100%;
  height: 250px;
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 15px;
  outline: 1px solid rgb(207, 217, 228);
  overflow: hidden;
}

.Dashboard_Grid_Left{
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid rgb(207, 217, 228);
  position: relative;
}

.Dashboard_Grid_Right{
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Dashboard_Grid_Ind{
  width: 5px;
  height: 100%;
  background-color: #ff9d64;
  position: absolute;
  left: 0px;
}

.Dashboard_Grid_Ind_Green{
  background-color: #3aca3f;
}

.Dashboard_Grid_Ind_Red{
  background-color: #ff574f;
}

.Dashboard_Con h3{
  margin: 0 3.75%;
  margin-top: 20px;
  font-size: 17px;
  font-weight: 600;
}

.Dashboard_Con h4{
  margin: 0;
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 40px;
  font-size: 17px;
  font-weight: 500;
  color: #000;
}

.Dashboard_Grid_Item1 h4{
  margin: 0;
  margin-left: 30px;
  margin-right: 20px;
}

.Dashboard_Con p{
  margin: 0;
  margin-left: 30px;
  margin-right: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #4b4b4b;
  margin-top: 10px;
}

.ToolTip{
  background-color: #fff;
  padding: 10px;
}